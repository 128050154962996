import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import AppStoreIcon from '@hero/ui-kit/graphics/AppStoreIcon';
import GooglePlayIcon from '@hero/ui-kit/graphics/GooglePlayIcon';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import MainMenu from '@hero/ui-kit/components/MainMenu';
import P from '@hero/ui-kit/typography/P';

const ios_app_link = 'https://apps.apple.com/us/app/hero-medication-manager/id1352848484';
const android_app_link = 'https://play.google.com/store/apps/details?id=com.herohealth.heroconnect';

const GetAppPage = () => {
    const isMinM = useIsMinBreakpoint('m');

    React.useEffect(() => {
        isAndroid && window.location.replace(android_app_link);
        isIOS && window.location.replace(ios_app_link);
    }, []);

    if (isAndroid || isIOS) return null;

    return (
        <>
            <MainMenu
                homePath="/"
                styles={{ maxWidth: '100%', height: '6rem', marginLeft: '2.4rem' }}
            />
            <Section centered centeredText noDefaultPadding styles={{ minHeight: '50vh' }}>
                <P
                    noDefaultMargin
                    centered
                    strong
                    styles={{ fontSize: '4.2rem', lineHeight: '5.2rem' }}
                >
                    Download the app <br /> on your mobile device
                </P>

                <Section noDefaultPadding marginVertical="large">
                    <Container
                        gridTemplateColumns={'1fr 1fr'}
                        gridColumnGap={isMinM ? 'large' : 'regular'}
                    >
                        <a rel="noreferrer" href={ios_app_link} title="Apple App Store">
                            <AppStoreIcon />
                        </a>

                        <a rel="noreferrer" href={android_app_link} title="Google Play Store">
                            <GooglePlayIcon />
                        </a>
                    </Container>
                </Section>
            </Section>
        </>
    );
};

export default GetAppPage;
